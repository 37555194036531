import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './SearchNavbar.css';
import LoginModal from './LoginModal';

const SearchNavbar = ({ onSearch }) => {
    const [filterType, setFilterType] = useState('Slang');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [transcript, setTranscript] = useState('');
    const { listening, resetTranscript, browserSupportsSpeechRecognition, finalTranscript } = useSpeechRecognition();

    useEffect(() => {
        console.log('Transcript updated:', transcript);
    }, [transcript]);

    useEffect(() => {
        if (finalTranscript !== '') {
            console.log('Final Transcript:', finalTranscript);
            setTranscript(finalTranscript);
        }
    }, [finalTranscript]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Search Term:', transcript);
        console.log('Filter Type:', filterType);
        onSearch(transcript, filterType);
    };

    const handleVoiceInput = () => {
        if (!browserSupportsSpeechRecognition) {
            alert('Your browser does not support speech recognition. Please use a different browser.');
            console.log('Browser does not support speech recognition');
            return;
        }

        if (listening) {
            SpeechRecognition.stopListening();
            console.log('Stopped listening');
        } else {
            resetTranscript();
            SpeechRecognition.startListening({ continuous: true, language: 'en-AU' });
            console.log('Started listening');
        }
    };

    const handleInputChange = (e) => {
        const newTranscript = e.target.value;
        console.log('Input Change:', newTranscript);
        setTranscript(newTranscript);
    };

    return (
        <nav className="search-navbar">
            <div className="logo-title">
                <h1 className="title">Australian Slang</h1>
            </div>
            <form className="search-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search Slang here..."
                    value={transcript}
                    onChange={handleInputChange}
                />
                <select
                    className="filter-select"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                >
                    <option value="Slang">Slang</option>
                    <option value="Explanation">Explanation</option>
                    <option value="Contributor">Contributor</option>
                </select>
                <button type="button" className="voice-button" onClick={handleVoiceInput}>
                    {listening ? 'Listening...' : '🎤'}
                </button>
                <button type="submit" className="search-button">Search</button>
            </form>
            <button onClick={() => setShowLoginModal(true)} className="join-us-button">Join Us</button>
            <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(false)} />
        </nav>
    );
};

export default SearchNavbar;